import React, { useState, useEffect } from 'react';
import './App.css';

import { Wheel } from 'react-custom-roulette';
import WebApp from '@twa-dev/sdk';

const getUrlParameter = (name) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
};

const defaultData = [
    { option: '1' },
    { option: '2' },
    { option: '3' },
    { option: '4' },
];

const backgroundColors = ['#cf3030', '#e58432', '#d2d456', '#9ccf57', '#88bbf7', '#81e6a8', '#ade169', '#40a09b'];
const textColors = ['#0b3351'];
const outerBorderColor = '#eeeeee';
const outerBorderWidth = 10;
const innerBorderColor = '#30261a';
const innerBorderWidth = 0;
const innerRadius = 0;
const radiusLineColor = '#eeeeee';
const radiusLineWidth = 8;
const fontFamily = 'Nunito';
const fontWeight = 'bold';
const fontSize = 20;
const fontStyle = 'normal';
const textDistance = 60;
const spinDuration = 1.0;

const App = () => {
    const [chatId, setChatId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [mustSpin, setMustSpin] = useState(false);
    const [prizeNumber, setPrizeNumber] = useState(0);
    const [winnerMessage, setWinnerMessage] = useState('');
    const [options, setOptions] = useState(defaultData);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [winMessage, setWinMessage] = useState('Congratulations! Dropped out {sector}');

    const user = WebApp.initDataUnsafe.user;
    const currentUserId = user?.id || null;
    const username = user ? `@${user.username}` || `${user.first_name} ${user.last_name}` : '';

    useEffect(() => {
        WebApp.expand();

        const extractedChatId = getUrlParameter('chat_id');
        setChatId(extractedChatId);

        if (extractedChatId) {
            fetchOptionsForChat(extractedChatId);
        } else {
            setLoading(false);
            setOptions(defaultData);
        }
    }, []);

    const fetchOptionsForChat = async (chatId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/get-options?chat_id=${chatId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json();

            const combinedOptions = [
                ...data.options.map(option => ({ option })),
                ...Object.entries(data.users)
                    .filter(([userId, userData]) => userId !== String(currentUserId))
                    .map(([_, userData]) => ({ option: userData.option })),
            ];

            setOptions(combinedOptions.length > 0 ? combinedOptions : defaultData);
            if (data.win_message) {
                setWinMessage(data.win_message);
            }
        } catch (error) {
            setOptions(defaultData);
        } finally {
            setLoading(false);
        }
    };

    const sendMessageToGroup = async (message) => {
        if (!chatId) {
            return;
        }

        const apiUrl = `${process.env.REACT_APP_API_URL}/send-message`;
        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    chatId: chatId,
                    message: message,
                }),
            });

            await response.json();
        } catch (error) {}
    };

    const handleSpinClick = () => {
        if (!mustSpin) {
            const newPrizeNumber = Math.floor(Math.random() * options.length);
            setPrizeNumber(newPrizeNumber);
            setMustSpin(true);
            setIsButtonDisabled(true);
        }
    };

    const handleStopSpinning = () => {
        setMustSpin(false);

        const winner = options[prizeNumber].option;
        const formattedMessage = winMessage
            .replace("{actionuser}", username)
            .replace("{sector}", winner);

        setWinnerMessage(formattedMessage);
        sendMessageToGroup(formattedMessage);

        setTimeout(() => {
            setWinnerMessage('');
            setIsButtonDisabled(false);
        }, 20000);
    };

    return (
        <div className="App">
            <header className="App-header">
                {loading ? (
                    <p>Data loading...</p>
                ) : (
                    <>
                        {winnerMessage && (
                            <div className="winner-message">{winnerMessage}</div>
                        )}
                        <Wheel
                            mustStartSpinning={mustSpin}
                            prizeNumber={prizeNumber}
                            data={options}
                            backgroundColors={backgroundColors}
                            textColors={textColors}
                            fontFamily={fontFamily}
                            fontSize={fontSize}
                            fontWeight={fontWeight}
                            fontStyle={fontStyle}
                            outerBorderColor={outerBorderColor}
                            outerBorderWidth={outerBorderWidth}
                            innerRadius={innerRadius}
                            innerBorderColor={innerBorderColor}
                            innerBorderWidth={innerBorderWidth}
                            radiusLineColor={radiusLineColor}
                            radiusLineWidth={radiusLineWidth}
                            spinDuration={spinDuration}
                            startingOptionIndex={2}
                            textDistance={textDistance}
                            onStopSpinning={handleStopSpinning}
                        />
                        <button className={'spin-button'}
                                onClick={handleSpinClick}
                                disabled={mustSpin || isButtonDisabled}
                        >
                            Spin
                        </button>
                    </>
                )}
            </header>
        </div>
    );
};

export default App;